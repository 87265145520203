// NextPage.js
import React from "react";
import "../App.css";
import NavBar from "../componets/Navbar";
import { useApplicant } from "../ApplicantContext";

function BasicInfo({ onBack, onNext }) {
  const { applicant, setApplicant } = useApplicant();

  return (
    <div>
      <h1>Personal Contact Information</h1>
      <p>* Indicates Required</p>

      <div class="label-class">
        <label class="label-text">
          Full name *
          <input
            type="text"
            class="input-field"
            value={applicant.fullName}
            onInput={(e) =>
              setApplicant({ ...applicant, fullName: e.target.value })
            }
          />
        </label>
        <label class="label-text">
          Email *
          <input
            type="text"
            class="input-field"
            value={applicant.email}
            onInput={(e) =>
              setApplicant({ ...applicant, email: e.target.value })
            }
          />
        </label>
        <label class="label-text">
          Phone number *
          <input
            type="text"
            class="input-field"
            value={applicant.phone}
            onInput={(e) =>
              setApplicant({ ...applicant, phone: e.target.value })
            }
          />
        </label>
      </div>

      <p class="page-counter">Page 1 of 3</p>
      <NavBar onBack={onBack} onNext={onNext} />
    </div>
  );
}

export default BasicInfo;
