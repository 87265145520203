// HomePage.js
import React from "react";
import "../App.css";
import logo from "../images/Wenham-HOUSE-LOGO.jpg";

function Homepage({ onNext }) {
  return (
    <div>
      <h1>The Wenham House Resident Application</h1>

      <div class="label-class">
        <img src={logo} id="logo" alt="Logo" />

        <p class="center-text">
          {" "}
          Thank you for your interest in The Wenham House
        </p>
        <p class="center-text">
          Please fill out a quick application to see if you are a good fit
        </p>
        <div class="startButton">
          <button onClick={onNext}>Begin Application</button>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
