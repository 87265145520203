// NextPage.js
import React from "react";
import "../App.css";
import { useApplicant } from "../ApplicantContext";

function Question({ onBack, onNext }) {
  const { applicant, setApplicant } = useApplicant();

  return (
    <div>
      <h1>Details</h1>
      <p>* Indicates Required</p>

      <div class="label-class">
        <label class="label-text">
          Have you had experience working the 12 steps before? *
          <textarea
            value={applicant.q1}
            onInput={(e) => setApplicant({ ...applicant, q1: e.target.value })}
            class="input-field2"
            rows="3"
          />
        </label>
        <label class="label-text">
          Describe how you would benefit from residence at The Wenahm House *
          <textarea
            value={applicant.q2}
            onInput={(e) => setApplicant({ ...applicant, q2: e.target.value })}
            class="input-field2"
            rows="3"
          />
        </label>
      </div>
      <p class="page-counter">Page 3 of 3</p>

      <div class="nav-container">
        <button class="nav-button" onClick={onBack}>
          Back
        </button>

        <button
          class="nav-button"
          onClick={() => {
            const fetchData = async () => {
              try {
                const response = await fetch(
                  `https://tou8w4bnva.execute-api.us-east-1.amazonaws.com/default/submit`,
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ applicant }), // Convert body to JSON
                  }
                );
                if (response.status === 200) {
                  onNext();
                }
              } catch (error) {
                console.log(error);
              }
            };

            fetchData(); // Call the async function
          }}
        >
          Submit Application
        </button>
      </div>
    </div>
  );
}

export default Question;
