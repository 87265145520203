// NextPage.js
import React from "react";
import "../App.css";
import { useApplicant } from "../ApplicantContext";

function Confirmation({ onBack, onNext }) {
  const { applicant, setApplicant } = useApplicant();

  return (
    <div>
      <h1>Next steps</h1>

      <div class="label-class">
        <h1>Application for {applicant.fullName} complete</h1>
        <p>Thank you for your interest in The Wenham House</p>
        <p>
          A copy of your application will be emailed to {applicant.email} for
          your record keeping
        </p>
        <p>
          If space allows, a manager of the house will be in contact with you
          soon.
        </p>
      </div>
    </div>
  );
}

export default Confirmation;
