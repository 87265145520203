// NavBar.js
import React from "react";
import "../App.css";
import { useApplicant } from "../ApplicantContext";

function Navbar({ onBack, onNext, currentPage }) {
  const { applicant, setApplicant } = useApplicant();

  return (
    <div class="nav-container">
      <button class="nav-button" onClick={onBack}>
        Back
      </button>
      <button class="nav-button" onClick={onNext}>
        Next
      </button>
    </div>
  );
}

export default Navbar;
