import { useContext, createContext, useState } from "react";

const ApplicantContext = createContext();

export function ApplicantProvider({ children }) {
  const [applicant, setApplicant] = useState({
    fullName: "",
    email: "",
    phone: "",
    adress: "",
    city: "",
    state: "",
    postal: "",
    country: "",
    q1: "",
    q2: "",
  });

  return (
    <ApplicantContext.Provider value={{ applicant, setApplicant }}>
      {children}
    </ApplicantContext.Provider>
  );
}

export function useApplicant() {
  return useContext(ApplicantContext);
}
