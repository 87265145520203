// App.js
import React, { useState } from "react";
import Homepage from "./pages/Homepage";
import BasicInfo from "./pages/BasicInfo";
import Area from "./pages/Area";
import Question from "./pages/Question";
import Confirmation from "./pages/Confirmation";
import { ApplicantProvider } from "./ApplicantContext";

function App() {
  const [currentPage, setCurrentPage] = useState(0);

  // Array of pages to be rendered based on currentPage state
  const pages = [
    <Homepage key="homepage" onNext={() => setCurrentPage(1)} />,
    <BasicInfo
      key="basicinfo"
      onBack={() => setCurrentPage(0)}
      onNext={() => setCurrentPage(2)}
    />,
    <Area
      key="area"
      onBack={() => setCurrentPage(1)}
      onNext={() => setCurrentPage(3)}
    />,
    <Question
      key="question"
      onBack={() => setCurrentPage(2)}
      onNext={() => setCurrentPage(4)}
    />,
    <Confirmation
      key="confirmation"
      onBack={() => setCurrentPage(3)}
      onNext={() => setCurrentPage(4)}
    />,
  ];

  return (
    <ApplicantProvider>
      <div>{pages[currentPage]}</div>
    </ApplicantProvider>
  );
}

export default App;
