// NextPage.js
import React from "react";
import "../App.css";
import NavBar from "../componets/Navbar";
import { useApplicant } from "../ApplicantContext";

function Area({ onBack, onNext }) {
  const { applicant, setApplicant } = useApplicant();

  return (
    <div>
      <h1>Location</h1>
      <p>* Indicates Required</p>

      <div class="label-class">
        <label class="label-text">
          Adress Line *
          <input
            type="text"
            class="input-field"
            value={applicant.adress}
            onInput={(e) =>
              setApplicant({ ...applicant, adress: e.target.value })
            }
          />
        </label>

        <div class="area-secondary">
          <label class="label-text-half">
            City *
            <input
              type="text"
              class="input-field"
              value={applicant.city}
              onInput={(e) =>
                setApplicant({ ...applicant, city: e.target.value })
              }
            />
          </label>
          <label class="label-text-half">
            State *
            <input
              type="text"
              value={applicant.state}
              onInput={(e) =>
                setApplicant({ ...applicant, state: e.target.value })
              }
              class="input-field"
            />
          </label>
        </div>

        <div class="area-secondary">
          <label class="label-text-half">
            Postal Code *
            <input
              type="text"
              class="input-field"
              value={applicant.postal}
              onInput={(e) =>
                setApplicant({ ...applicant, postal: e.target.value })
              }
            />
          </label>
          <label class="label-text-half">
            Country *
            <input
              type="text"
              value={applicant.country}
              class="input-field"
              onInput={(e) =>
                setApplicant({ ...applicant, country: e.target.value })
              }
            />
          </label>
        </div>
      </div>
      <p class="page-counter">Page 2 of 3</p>

      <NavBar onBack={onBack} onNext={onNext} />
    </div>
  );
}

export default Area;
